<template>
  <div class="creditHours">
    <div class="classify-box flex_row p_in_10 m_b_10">
      <div class="classifies flex_row_wrap fs_14">
        <!--    目录    -->
        <span class="classifies-options m_r_30 pointer" :class="item.id === courseParams.classifyId?'selected':''"
              v-for="item of optionsData"
              :key="item.id" @click="selectOptions_click(item.id)">
          {{ item.name }}
        </span>
      </div>
      <div>
        <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="selectDate_change"
            value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="data-box flex_row m_t_15">
      <div class="echartsBox p_in_10 br_5" ref="echartsBox">
        <div class="generalization flex_row">
          <div class="flex_col">
            <span class="fs_30 fw_bold">{{ hours.countHour }}</span>
            <span class="c_7c">累计学时</span>
          </div>
          <div class="flex_col">
            <span class="fs_30 fw_bold">{{ hours.countToday }}</span>
            <span class="c_7c">今日学时</span>
          </div>
        </div>
        <!--    饼图    -->
        <div class="echarts" ref="echarts" v-if="echartsData&&echartsData.length>0"></div>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>
      <div class="courseBox p_in_10 fs_14">
        <!--    课程列表    -->
        <template v-if="courseList.length!==0">
          <div class="course flex_row p_in_10 br_5 p_lr_20" v-for="(item, index) of courseList"
               :key="index">
          <span class="flex_row">
            <img :src="videoImg" alt="" height="100%">
            <span class="m_lr_10">{{ item.name }}</span>
          </span>
            <span class="c_7c">1学时</span>
            <span class="c_7c">{{ item.create_time }}</span>
          </div>
        </template>
        <el-empty description="暂无数据" v-else></el-empty>
        <!--    分页    -->
        <el-pagination
            class="pagination m_t_15"
            background
            layout="prev, pager, next"
            @current-change="current_change"
            :page-size="10"
            :total="total"
            hide-on-single-page>
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

import videoImg from '@/assets/image/video.png'
// eslint-disable-next-line no-unused-vars
import {getCourseCreditHours, getCreditHoursStatistics} from '@/api/mine/teacher'
import {sessionGet} from "../../../utils/local";
import {getResourcesClassify} from "../../../api/index";

export default {
  name: 'CreditHours',
  data: () => {
    return {
      videoImg,
      userInfo: '',
      // 目录数据
      optionsData: [
        {name: '全部', id: ''},
        {name: '课程教学', id: '1'},
        {name: '专题教学', id: '2'},
        {name: '课后服务', id: '3'},
        {name: '通识研修', id: '4'}
      ],
      // echarts 数据
      echartsData: null,
      // 获取列表参数
      courseParams: {
        // 页数
        current: 1,
        // 每页数量
        size: 10,
        // 目录id
        classifyId: '',
        // 用户id
        userId: '',
        // 开始日期
        startTime: '',
        // 介绍日期
        endTime: ''
      },
      // 时长
      hours: {
        countHour: '',
        countToday: ''
      },
      // 课程列表
      courseList: [],
      // 分页
      total: 0,
      // 日期
      date: ''
    }
  },
  mounted() {
    this.userInfo = sessionGet('userInfo')
    this.courseParams.userId = this.userInfo.user_id
    this.getClassifyList()
    // this.getChartData()
    this.getCourseList()

    // setTimeout(() => {
    this.getChartData()
    // }, 500)
  },
  methods: {
    selectOptions_click(id) {
      this.courseParams.classifyId = id
      this.getCourseList()
    },
    // 获取目录数据
    getClassifyList() {
      getResourcesClassify().then(res => {
        this.optionsData = [{id: '', name: '全部'}].concat(res.data.records)
      })
    },
    // 获取饼图数据
    getChartData() {
      let userId = this.userInfo.user_id
      getCreditHoursStatistics({userId}).then(res => {
        this.hours = {
          countHour: res.data.countHour,
          countToday: res.data.countToday
        }
        this.echartsData = res.data.map
        if (res.data.map.length > 0) {
          this.charting(res.data.map)
        }
      })
    },
    // 获取课程列表数据
    getCourseList() {
      getCourseCreditHours(this.courseParams).then(res => {
        this.total = res.data.total
        this.courseList = res.data.records
      })
    },
    // 绘制饼图
    charting(data) {
      // 基于准备好的dom，初始化echarts实例
      let myChart
      setTimeout(() => {
        myChart = echarts.init(this.$refs.echarts);
        // echarts 配置
        let echartsOptions = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}:  {c} 学时'
          },
          legend: {
            show: false,
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: '学时统计',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: true,
                position: 'inner',
                formatter: `{d}%`
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '20',
                  fontWeight: 'bold',
                }
              },
              labelLine: {
                show: true
              },
              data: data
            }
          ]
        }
        // 绘制图表
        myChart.setOption(echartsOptions);
      }, 100)
    },
    // 选择日期
    selectDate_change(date) {
      if (!date) {
        this.courseParams.startTime = this.courseParams.endTime = ''
      } else {
        this.courseParams.startTime = `${date[0]}`
        this.courseParams.endTime = `${date[1]}`
      }
      this.getCourseList()
    },
    // 点击分页
    current_change(current) {
      this.courseParams.current = current
      this.getCourseList()
    }
  }
}
</script>

<style scoped>
.creditHours {
  width: 100%;
  min-height: 60%;
  box-shadow: 1px 1px 20px #eee;
  background: #fff;
}

.classify-box {
  justify-content: space-between;
  max-height: 60px;
  overflow: hidden;
  width: 100%;
  transition: max-height 500ms;
}

.classify-box:hover {
  max-height: 180px;
  transition: max-height 1000ms;
}

.classifies {
  line-height: 40px;
  color: #a8a8a8;
  min-width: 290px;
}

.classifies-options:hover {
  color: #4590f8;
}

.el-range-editor.el-input__inner {
  justify-content: space-between;
}


.data-box {
  justify-content: space-between;
}

.echartsBox {
  width: 25%;
  background-color: #fcfcfc;
  margin-right: 10px;
  padding-top: 30px;
}

.courseBox {
  width: 75%;
  /*height: 300px;*/
  position: relative;
}

.selected {
  color: #4590f8;
}

.generalization {
  text-align: center;
  justify-content: space-around;
}

.echarts {
  width: 100%;
  height: 300px;
}

.course {
  width: 100%;
  height: 60px;
  justify-content: space-between;
  line-height: 40px;
}

.course:nth-child(2n) {
  background-color: #fcfcfc;
}

.c_7c {
  color: #7c7c7c;
}

.el-input-number, .el-date-editor.el-input, .el-date-editor.el-input__inner, .el-select, .el-cascader {
  width: 350px;
}

.pagination {
  margin: 0 auto;
  position: absolute;
  bottom: 10px;
}
</style>