import { render, staticRenderFns } from "./CreditHours.vue?vue&type=template&id=35de2af2&scoped=true"
import script from "./CreditHours.vue?vue&type=script&lang=js"
export * from "./CreditHours.vue?vue&type=script&lang=js"
import style0 from "./CreditHours.vue?vue&type=style&index=0&id=35de2af2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35de2af2",
  null
  
)

export default component.exports